import { ForFamilyMembers } from "./components/ForFamilyMembers";
import { Faqs } from "./components/Faqs";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Hero } from "./components/Hero";
import { Pricing } from "./components/Pricing";
import { WorksInternationally } from "./components/WorksInternationally";
import LoadingSpinner from "~/components/loading-spinner";
import { SecondaryFeatures } from "~/marketing-site/components/SecondaryFeatures";
import { ForSeniors } from "~/marketing-site/components/ForSeniors";
import { InitAnonymousIntercom } from "~/Intercom";
import React from "react";
import { OurStory } from "~/marketing-site/components/OurStory";

export default function Home() {
  return (
    <>
      <Header />
      <LoadingSpinner />
      <main>
        <Hero />
        <SecondaryFeatures/>
        {/*<PrimaryFeatures />*/}
        <WorksInternationally />
        <ForFamilyMembers/>
        <ForSeniors/>
        {/*<CallToAction />*/}
        {/*<Testimonials />*/}
        <Pricing />
        <OurStory/>
        <Faqs />
      </main>
      <Footer />
      <InitAnonymousIntercom/>
    </>
  );
}
